@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* background-image: url('../public/images/sunset-2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; */
  min-height: 100vh;
  font-family: 'Playfair Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}