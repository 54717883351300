/* html, body {
    overscroll-behavior: none;
  } */

.fixed-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../public/images/sunset-2.jpg');
    background-size: cover;
    background-position: center;
    z-index: -2;
}