@tailwind base;
@tailwind components;
@tailwind utilities;

.header {
    @apply fixed top-0 w-full h-auto m-0 duration-300 ease-in-out z-50;
}

.header-bg {
    @apply fixed top-0 bg-headerGray bg-opacity-85 backdrop-blur w-full h-auto m-0 duration-300 ease-in-out z-50;
}

@layer components {
    .nav-links {
        @apply py-5 px-3 text-white text-lg transition-all duration-300 relative;
    }

    .nav-links::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: theme('colors.sunset');
        transition: width .3s ease-in-out;
        max-width: calc(100% - 1.5rem);
        position: absolute;
        left: 0.75rem;
        bottom: 1.25rem;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-links:hover::after {
        width: 100%;
    }

    .nav-links.active::after {
        width: calc(100% - 1.5rem);
    }
}


.nav-links-mobile {
    @apply py-2 px-2 flex flex-col items-start relative text-white;
}


@layer components {
    .title-links {
        @apply py-5 px-2 text-white text-3xl transition-all duration-300 relative;
    }

    .title-links::after {
        content: '';
        display: block;
        width: 0;
        height: 3px;
        /* or '1px' for a thinner underline */
        background: theme('colors.sunset');
        /* uses the text color */
        transition: width .3s ease-in-out;
        max-width: calc(100% - 1rem);
        position: absolute;
        left: 0.5rem;
        bottom: 1.2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .title-links:hover::after {
        width: 100%;
    }
}

.icon {
    @apply h-8 w-10 px-1 text-white;
}