@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .testimonial-box {
    @apply p-4 border border-sky rounded-lg shadow-lg bg-sky bg-opacity-5;
  }

  .testimonial-text {
    @apply text-black leading-relaxed font-body italic;
  }

  .testimonial-author {
    @apply text-sky font-body font-medium mt-3 block;
  }
}


.body-text {
  @apply font-body;
}

.headline {
  @apply px-2 pt-32 text-3xl font-bold text-center;
}

.contact-btn {
  @apply font-body bg-ctaTeal backdrop-blur-sm text-white font-bold py-2 px-5 rounded-lg text-lg transition-all duration-300;
}

.contact-btn:hover {
  @apply bg-opacity-65;
}

.footer-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-footer {
  width: 20px;
  height: 20px;
  color: theme('colors.ctaTeal');
  transition: width 0.3s ease, height 0.3s ease;
}

.footer-link:hover .icon-footer {
  width: 24px;
  height: 24px;
}

.form-input {
  @apply font-body shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none text-sm;
}

.submit-btn {
  @apply w-full font-body bg-ctaTeal backdrop-blur-sm text-white font-bold py-2 px-2 rounded-lg text-sm transition-all duration-300;
}

.submit-btn:disabled {
  @apply bg-opacity-65;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(218, 210, 210, 0.2); 
  z-index: 10; 
}

.input-wrapper {
  position: relative; 
  display: inline-block; 
  width: 100%; 
}

.success-message {
  @apply pt-2 text-success font-body text-sm
}

.error-message {
  @apply pt-2 text-fail font-body text-sm
}
